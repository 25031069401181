import mapboxgl from 'mapbox-gl';
import React, {useEffect, useRef} from 'react';
import {createRoot} from 'react-dom/client';
import './Map.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import 'font-awesome/css/font-awesome.min.css';
import {faCircle} from '@fortawesome/free-solid-svg-icons'
import useWebSocket from 'react-use-websocket';
import {SocketStatus, StatusEnum} from "./SocketStatus";


mapboxgl.accessToken =
    'pk.eyJ1IjoiaXpha3phbmQiLCJhIjoiY2p6aGUweHE1MG1uMjNsbmc3anA0Z2RyYiJ9.fsGGmHBMKzdehQdlEPMxhw';

const Marker = ({onClick, children, description}) => {
    const _onClick = () => {
        onClick(description);
    };

    return (
        <button onClick={_onClick} className="marker">
            <FontAwesomeIcon icon={faCircle}/>
        </button>
    );
};

const Map = () => {
    const socketUrl = 'wss://p2000-back.izak.dev/websocket';
    const { lastJsonMessage, readyState} = useWebSocket(socketUrl, {
        onMessage: () => console.log,
        onOpen: () => console.log('opened'),
        shouldReconnect: (closeEvent) => true,
    });
    const mapContainerRef = useRef(null);
    const map = useRef(null)

    useEffect(() => {
        if (lastJsonMessage == undefined)
            return;
        console.log(lastJsonMessage)

        if (lastJsonMessage.hasOwnProperty("coords")) {
            // Create a React ref
            const ref = React.createRef();
            // Create a new DOM node and save it to the React ref
            ref.current = document.createElement('div');
            // Render a Marker Component on our new DOM node
            createRoot(ref.current).render(
                <Marker onClick={markerClicked} description={lastJsonMessage.message}/>
            );


            // Create a Mapbox Marker at our new DOM node
            const marker = new mapboxgl.Marker(ref.current)
                .setLngLat([lastJsonMessage.coords["lon"], lastJsonMessage.coords["lat"]])

            marker.addTo(map.current);

            setTimeout(() => {
                marker.remove()
            }, 1000 * 60)

        }
    }, [lastJsonMessage]);

    // Initialize map when component mounts
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [5.7396, 52.1272],
            zoom: 7
        });

    }, []);

    const markerClicked = (title) => {
        window.alert(title);
    };

    return <>
        <SocketStatus status={readyState}/>
        <div className="map-container" ref={mapContainerRef}/>
    </>;
};

export default Map;
