import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {ReadyState} from "react-use-websocket";


class StatusItem {
    constructor(text, color, blink = false) {
        this.text = text
        this.color = color
        this.blink = blink
    }
}



export const SocketStatus = ({status}) => {
    switch (status) {
        case ReadyState.OPEN:
            status = new StatusItem("Live", "#77D51E", true);
            break;
        case ReadyState.CONNECTING:
            status = new StatusItem("Connecting...", "#e67e22");
            break;
        case ReadyState.CLOSED:
            status = new StatusItem("Offline", "#E74C3C");
            break;
        default:
            status = new StatusItem("Reconnecting..", "#e67e22", true);
    }

    return (<div style={{position: "fixed", top: 30, left: 30, zIndex: 999}}>
        <div style={{marginTop: 0, backgroundColor: "white", padding: "1em 2em", borderRadius: "1em"}}>
            <h1 style={{fontWeight: "bold", marginBottom: ".5em"}}>P2000 Live map</h1>
            <span style={{color: status.color, fontWeight: "bold"}}>
                <FontAwesomeIcon icon={faCircle} beat={true}/>
                &nbsp;&nbsp;{status.text}
            </span>
        </div>
    </div>)
}